<template>
    <div class="common_content join_brand_box">
         <div class="social_title animated fadeInDown">
            品牌加盟 <br/><span>/  咨询热线：400-1390-555</span>
        </div>
        <div class="s_title_small animated bounceInRight"><span>滚动鼠标继续   <span style="color:#EE660E">/</span>   Scroll to Next</span><img src="../../assets/groupIntro/mouse_icon.gif"/></div>
        <div class="line_b"></div>
        <div class="cond_box">
            <div class="cond_title animated fadeInDown">加盟条件 <span>/ JOIN CONDITIONS</span></div>
            <div class="cond_content">
                <div class="cond_item animated fadeInDown">
                    <ul>
                        <li><span>○ </span>具有合法资格的法人或自然人。</li>
                        <li><span>○ </span>具有管理协调能力，有渠道经营经验。</li>
                        <li><span>○ </span>具有强烈的事业心，并追求成功的欲望。</li>
                        <li><span>○ </span>具有一定的经济实力及长远的投资眼光。</li>
                        <li><span>○ </span>能正确看待和承担风险意识。</li>
                        <li><span>○ </span>应在其指定销售区域进行销售，不得跨区经营。</li>
                        <li><span>○ </span>各加盟商需向我司提出书面申请，如实填写各项内容。</li>
                    </ul>
                </div>
                <div class="cond_item animated bounceInRight delay-c2s">
                    <img src="../../assets/joinBrand/join_cond_bg.png"/>
                </div>
            </div>
        </div>
        <div class="line_b" style="margin-top:80px"></div>
        <div class="cond_box">
            <div class="cond_title" id="text_1">加盟流程 <span>/ JOINING PROCESS</span></div>
            <div class="cond_content_white" style="margin-top:80px">
                <div class="join_process">
                    <img id="text_2" src="../../assets/joinBrand/join_process.png"/>
                </div>
            </div>
        </div>
        <div class="line_b" style="margin-top:80px"></div>
        <div class="cond_box">
            <div class="cond_title" id="text_3">运营优势 <p>线上线下结构均衡且高度融合的全渠道销售网络</p><div class="bg_num" id="text_4">01</div></div>
            <div class="cond_content cond_content_white" style="margin-top: 30px;">
                <div class="cond_item">
                    <ul class="pd_60" id="text_6">
                        <li >
                            琪尔特集团有着近二十年线下品牌渠道开拓与运营管理能力，拥有鞋服行业线
                            上线下结构均衡且高度融合的全渠道销售网络。并将产业链优势与直播新零售结合，
                            建设成现代化、专业化、智慧化、平台化的直播基地，为企业和
                            品牌提供全域营销助力，共享新零售大时代。
                        </li>
                        <li style="margin-top:50px">
                            <img  src="../../assets/joinBrand/ys_01.png"/>
                        </li>
                    </ul>
                </div>
                <div class="cond_item" >
                    <img id="text_5" src="../../assets/joinBrand/ys_02.png"/>
                </div>
            </div>
        </div>
        <div class="line_b" style="margin-top:80px"></div>
        <div class="cond_box">
            <div class="cond_title" id="text_7">产品优势 <span>/ 产品科研创新能力</span><div class="bg_num" id="text_8">02</div></div>
            <div class="cond_content cond_content_white" style="margin-top: 30px;">
                <div class="cond_item" id="text_9">
                    <ul class="" style="
    margin-top: 212px;
">
                        <li>
                            基于历年在鞋服设计、研发中的探索与实践，琪尔特集团不仅建立了全品类科技的“科技智库”体系，
                            并且设立科技智库研究中心进行健康数据分析研究，最终确立以“创新智造”为核心，
                            整合优势资源以求促进整个产业的转型升级，延伸鞋服产业的生产边界，立志打造多维度的科技支撑品牌的战略定位。
                            并且以客户需求为导向，以提高质量和效率为目标，
                            以整合资源为手段，实现产品设计、采购、生产、销售、服务等全过程高效协同的组织形态。
                        </li>
                    </ul>
                </div>
                <div class="cond_item" >
                    <img id="text_10" src="../../assets/joinBrand/ys_03.png"/>
                </div>
            </div>
        </div>
        <div class="line_b" style="margin-top:80px"></div>
        <div class="cond_box">
            <div class="cond_title" id="text_11">质量优势 <span>/ 产品的质量保证</span><div class="bg_num" id="text_12">03</div></div>
            <div class="cond_content cond_content_white cond_custom">
                <div class="cond_item">
                    <div class="year_box">
                        <div class="c_year"><ICountUp v-if="t1" class="cc" :delay="300" :endVal="2009" :options="options"/> <span>年</span></div>
                        <div class="c_text">
                            <span id="text_13">荣获全国卫生产业企业管理协会抗菌产业分会颁发的“卫生、抗菌”认证。</span>
                        </div>    
                    </div>
                </div>
                <div class="cond_item">
                    <div class="year_box">
                        <div class="c_year"><ICountUp v-if="t1" class="cc" :delay="300" :endVal="2019" :options="options"/> <span>年</span></div>
                        <div class="c_text">
                            <span class="animated" id="text_14">荣获“中国服装品牌最具创新力企业”认证</span>
                        </div>
                    </div>
                </div>
                <div class="cond_item">
                    <div class="year_box">
                        <div class="c_year"><ICountUp v-if="t1" class="cc" :delay="300" :endVal="2015" :options="options"/> <span>年</span></div>
                        <div class="c_text">
                            <span class="animated" id="text_15">亚洲品牌盛典举行，琪尔特集团成为“中国行业品牌最具创新力企业”，<br/>
                            董事长林玉琪荣获“中国行业品牌十大创新人物”</span>
                        </div>
                    </div>
                </div>
                <div class="cond_item">
                    <div class="year_box">
                        <div class="c_year"><ICountUp v-if="t1" class="cc" :delay="300" :endVal="2017" :options="options"/> <span>年</span></div>
                        <div class="c_text" >
                            <span class="animated" id="text_16">荣膺“中国500最具价值品牌”，系集团连续3年荣膺。</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="line_b" style="margin-top:80px"></div>
        <div class="cond_box">
            <div class="cond_title animated" id="text_17">推广支持 <p>定期专业培训，节日及新品上市的促销策划、指导、支持</p><div class="bg_num animated" id="text_18">04</div></div>
            <div class="cond_content cond_content_white" style="margin-top: 30px;">
                <div class="cond_item">
                    <ul class="pd_60" style="
    margin-top: 182px;
">
                        <li class="animated" id="text_19">
                           提供系统的终端培训管理，定期对终端员工进行专业培训，
                           助推市场健康运营。线上线下多渠道品牌推广支持。
                           提供选址、开业、终端形象设计等开店服务支持，提供全年的活动促销建议、终端物料设计等支持。
                        </li>
                    </ul>
                </div>
                <div class="cond_item" >
                    <img class="animated" id="text_20" src="../../assets/joinBrand/support_bg.png"/>
                </div>
            </div>
        </div>
        <div class="line_b" style="margin-top:80px"></div>
        <div class="line_title">加盟申请表单</div>
        <div class="c_flex">
            <div class="flex_item">
                大嘴猴品牌加盟咨询专线：400-1380-999
            </div>
            <div class="flex_item">
                琪尔特/巴布豆加盟咨询专线：400-1390-555
            </div>
            <div class="flex_item">
                波波泰迪品牌加盟咨询专线：400-1390-555
            </div>
        </div>
        <div class="cus_form">
            <el-form :inline="true" :model="formInline" ref="ruleForm" :rules="rules" class="demo-form-inline">
                <el-form-item label="" prop="name" >
                    <el-input v-model="formInline.name" placeholder="姓名（必填）" class="cus_input"></el-input>
                </el-form-item>
                <el-form-item label="" prop="phone">
                    <el-input v-model="formInline.phone" placeholder="电话（必填）" class="cus_input"></el-input>
                </el-form-item>
                <el-form-item label="" prop="city_name">
                    <el-input v-model="formInline.city_name" placeholder="意向加盟城市（必填）" class="cus_input_2"></el-input>
                </el-form-item>
                <el-form-item label="" prop="wechat">
                    <el-input v-model="formInline.wechat" placeholder="微信号" class="cus_input"></el-input>
                </el-form-item>
                <el-form-item label="" prop="join_brand">
                    <el-input v-model="formInline.join_brand" placeholder="意向加盟品牌" class="cus_input"></el-input>
                </el-form-item>
                <el-form-item label="" prop="area_size">
                    <el-input v-model="formInline.area_size" placeholder="意向店铺面积" class="cus_input"></el-input>
                </el-form-item>
                <el-form-item label="" prop="amount">
                    <el-input v-model="formInline.amount" placeholder="意向投资金额" class="cus_input"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-select v-model="formInline.has_join_other" placeholder="是否有过加盟其他品牌的经历" class="cus_input">
                    <el-option label="是" :value="1"></el-option>
                    <el-option label="否" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-select v-model="formInline.has_store" placeholder="是否有待开业的商铺" class="cus_input">
                    <el-option label="是" :value="1"></el-option>
                    <el-option label="否" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="width:100%;text-align:center;position: relative;">
                    <el-button type="primary" @click="onSubmit" :loading="loading">提交申请</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import ICountUp from 'vue-countup-v2';
export default {
    components: {
      ICountUp
    },
    data() {
        return {
            loading: false,
            formInline: {
                name: '',
                phone: '',
                city_name: '',
                wechat: '',
                join_brand: '',
                area_size: '',
                amount: '',
                has_join_other: '',
                has_store: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入电话号码', trigger: 'blur' },
                ],
                city_name: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' },
                ],
            },
            options: {
                useEasing: true,
                useGrouping: false,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ''
            },
            t1: false
        }
    },
    created() {

    },
    mounted(){
        window.addEventListener('scroll', this.getScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.getScroll);
    },
    methods:{
        onSubmit() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$http.post(this.$apiUrl.common.joinIn,this.formInline).then((res) => {
                        if (res.code === 1000) {
                            this.$message.success(res.message)
                        }
                        this.loading = false;
                    }).catch(()=>{
                        this.loading = false;
                    });
                } else {
                    this.$message.warning('请填写所有必填项')
                    return false;
                }
            });
        },
        getScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            this.scrollTop =scrollTop;
            if (100 < scrollTop && scrollTop < 130) {
                let dom1 = document.getElementById('text_1');
                dom1.classList.add('animated');
                dom1.classList.add('fadeInDown');
            }else if(130<scrollTop && scrollTop < 600){
                let dom1 = document.getElementById('text_2');
                dom1.classList.add('animated');
                dom1.classList.add('bounceInRight');
                
            } else if(750<scrollTop && scrollTop < 830){
                let dom3 = document.getElementById('text_3');
                dom3.classList.add('animated');
                dom3.classList.add('fadeInDown');
                let dom4 = document.getElementById('text_4');
                dom4.classList.add('animated');
                dom4.classList.add('slideInRight');

               
            } else if(830<scrollTop && scrollTop < 1200){
                let dom3 = document.getElementById('text_5');
                dom3.classList.add('animated');
                dom3.classList.add('bounceInRight');

               let dom4 = document.getElementById('text_6');
                dom4.classList.add('animated');
                dom4.classList.add('fadeInDown');
            } else if(1400<scrollTop && scrollTop < 1800){
                let dom3 = document.getElementById('text_7');
                dom3.classList.add('animated');
                dom3.classList.add('fadeInDown');

                let dom4 = document.getElementById('text_8');
                dom4.classList.add('animated');
                dom4.classList.add('slideInRight');

                let dom5 = document.getElementById('text_9');
                dom5.classList.add('animated');
                dom5.classList.add('fadeInDown');

                let dom6 = document.getElementById('text_10');
                dom6.classList.add('animated');
                dom6.classList.add('bounceInRight');
                dom6.classList.add('delay-c2s');
                

            }else if(2047<scrollTop && scrollTop < 2200){
                let dom3 = document.getElementById('text_11');
                dom3.classList.add('animated');
                dom3.classList.add('fadeInDown');

                let dom4 = document.getElementById('text_12');
                dom4.classList.add('animated');
                dom4.classList.add('slideInRight');
                
                let dom5 = document.getElementById('text_13');
                dom5.classList.add('animated');
                dom5.classList.add('fadeInDown');
                let dom6 = document.getElementById('text_14');
                dom6.classList.add('fadeInDown');
                let dom7 = document.getElementById('text_15');
                dom7.classList.add('fadeInDown');
                let dom8 = document.getElementById('text_16');
                dom8.classList.add('fadeInDown');
                

            }else if(2200<scrollTop && scrollTop < 2700){
                this.t1 = true

            }else if(2700<scrollTop && scrollTop < 2800){
                 let dom3 = document.getElementById('text_17');
                dom3.classList.add('fadeInDown');

                let dom4 = document.getElementById('text_18');
                dom4.classList.add('slideInRight');

            }else if(2800<scrollTop && scrollTop < 3200){
                let dom3 = document.getElementById('text_19');
                dom3.classList.add('fadeInDown');

                let dom4 = document.getElementById('text_20');
                dom4.classList.add('bounceInRight');
                dom4.classList.add('delay-c2s');

            }
        },
    }
}
</script>
<style scoped>
.common_content{
    margin-left: 370px;
    margin-top: 4vh;
}
.join_brand_box{
    margin-right: 240px;
}
.social_title{
font-size: 46px;
font-family: AlibabaPuHuiTiB;
font-weight: bolder;
color: #000000;
}
.social_title span{
    font-size: 25px;
    color: #EE660E;
}
.line_b{
    height: 2px;
background: #F2F2F2;
margin: 36px 0 26px 0;
}
.s_title_small{
  font-size: 17px;
font-family: AlibabaPuHuiTiL;
font-weight: 400;
color: #000000;
    padding-top: 35px;
        text-align: right;
}
.s_title_small img{
      vertical-align: middle;
    margin-left: 5px;
}
.cond_box{

}
.cond_title{
font-size: 33px;
font-family: AlibabaPuHuiTiB;
font-weight: bolder;
color: #221815;
margin-top: 80px;
position: relative;
}
.cond_title span{
font-size: 17px;
color: #5A5A5A;
}
.cond_title p{
font-size: 17px;
margin: 0 0 0 20px;
padding: 0;
color: #5A5A5A;
}
.cond_title .bg_num{
font-size: 80px;
    font-family: ZurichBT;
    font-weight: bold;
    color: #EE660E;
    opacity: 0.1;
    position: absolute;
    top: -33px;
    left: 88px;
}
.cond_content{
    display: flex;
    background: #F7F7F7;
    justify-content: space-between;
    margin-top: 51px;
}
.cond_content_white{
    background: #ffffff;
}
.cond_item{
    width: 46%;
}

.cond_item ul{
    font-size: 15px;
    list-style: none;
        margin: 24px 37px;
    padding: 0;
    letter-spacing: 2px;
}
.cond_item ul.pd_60{
    padding: 0 10px 0 0;
}
.cond_item ul li{
    color: #000000;
    line-height: 1.7;
}
.cond_item ul li span{
    color: #EE660E;
    font-size: 24px;
}
.cond_item img{
    width: 100%;
}
.year_box{
position: relative;
}
.c_year{
    font-size: 72px;
    font-family: AlibabaPuHuiTiH;
    font-weight: bold;
    color: #221815;
    display: inline-block;
    position: relative;
    top: 26px;
    left: 45px;
}
.c_year span{
font-size: 29px;
color: #221815;
}
.cc{
    font-size: 72px !important;
    font-family: AlibabaPuHuiTiH;
    font-weight: bold !important;
}
.c_text{
font-size: 16px;
font-family: AlibabaPuHuiTiR;
font-weight: 400;
color: #000000;
background: #F7F7F7;
    padding: 32px 48px 48px 32px;
}
.cond_custom{
    flex-wrap: wrap;
}
.cond_custom .cond_item{
    width: 46%;
}
.join_process img{
    width: 100%;
}
.line_title{
background: #EE660E;
margin: 36px 0 26px 0;
font-size: 33px;
font-family: AlibabaPuHuiTiB;
font-weight: 400;
color: #FFFFFF;
height: 88px;
line-height: 88px;
text-align: center;
}
.c_flex{
    display: flex;
    justify-content: space-between;
}
.flex_item{
    font-size: 17px;
font-family: AlibabaPuHuiTiR;
font-weight: 400;
color: #000000;
}
.cus_form{
    padding: 20px 0 50px 0;
}
.el-button--primary{
    background-color: #EE660E;
    border-color: #EE660E;
}
.cus_input{
    width: 700px;
}
.cus_input_2{
width: 1410px;
}
@media screen and (max-width:1500px){
    .cus_input{
    width: 425px;
}
.cus_input_2{
width: 860px;
}
    .cond_item ul{
        font-size: 14px;
        margin: 20px;
    }
    .cond_item ul li span{
        font-size: 16px;
    }
    .cond_item ul.pd_60{
        padding: 0;
    }
    .flex_item{
        font-size: 14px;
    }
}
.delay-c2s{
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
}
</style>